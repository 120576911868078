.root {
  width: 100%;
  height: 100%;
  .stack__full {
    width: 100%;
    height: 100%;
  }

  .stack__fitcontent {
    width: 100%;
  }

  .privacy_screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 2;
  }
}
