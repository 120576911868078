.root {
  width: 100%;
  height: 100%;
  position: relative;
  .button {
    position: absolute;
    top: 30vh;
    left: 5vw;
    color: grey;
  }
}
