.root {
  height: 100%;
  width: 100%;
  .appbar {
    height: 100%;
    z-index: 999;
    padding: 0 15px;
    background: var(--color-sub);
    .toolbar {
      padding: 0;
      .title {
        flex-grow: 1;
        .title_text {
          font-size: 100%;
        }
      }
    }
  }
}
