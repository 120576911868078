.grid_item {
  width: 100%;
  height: 100%;
  .croduct_card_button {
    width: 100%;
    height: 100%;
    .product_card {
      width: 100%;
      height: 100%;
    }
  }
}
