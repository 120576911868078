.root {
  width: 100%;
  height: 100%;
  .header {
    height: 7%;
  }
  .banner {
    height: 93%;
  }
  .stack {
    height: 93%;
  }
  .content {
    height: 100%;
  }
}
